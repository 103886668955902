import React, { useEffect, useState } from "react";
import style from "./LoginPage.module.css";
import { useStore } from "../store";
import { getTranslation, languageDisplay } from "../Utility";
import FailPage from "./FailPage";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../SocketContext";
function LoginPage() {
  const socket = useSocket();
  const navigate = useNavigate();
  const { setToken, setMb_name, language, setLanguage, setWhere } = useStore();

  const [userId, setUserId] = useState("");
  const [userPw, setUserPw] = useState("");
  const [userToken, setUserToken] = useState("");
  const [userName, setUserName] = useState("");

  const [langDropdownOpen, setLangDropdownOpen] = useState(false);
  const [Terms, setTerms] = useState(false);
  const [LoginFail, setLoginFail] = useState(false);
  const paragraphs = getTranslation(language, "terms_text")
    .split("\n")
    .filter((line) => line.trim() !== "");

  function GoLobby() {
    setToken(userToken);
    setMb_name(userName);
    setWhere("login");
    navigate("/Lobby");
  }

  // 언어 변경 시 쿠키를 설정하고 선택된 언어를 업데이트하는 함수
  const changeLangCookie = (lang) => {
    setLanguage(lang);
    setLangDropdownOpen(false); // 언어 선택 후 드롭다운 닫기
  };

  // 언어 드롭다운 토글 함수
  const toggleDropdown = () => {
    setLangDropdownOpen(!langDropdownOpen);
  };

  // 게스트 로그인 버튼 클릭 시 호출되는 함수
  const handleGuestLogin = () => {
    window.location.href = "/guestLogin";
  };

  // 로그인 폼 제출 처리 함수
  const handleSubmit = (e) => {
    e.preventDefault();
    if (!userId || !userPw) return;

    if (!socket) {
      console.warn("소켓이 아직 초기화되지 않았습니다.");
      return;
    }
    socket.emit("LoginRequest", { id: userId, pw: userPw });
  };
  useEffect(() => {
    if (!socket) return;

    socket.on("LoginFail", () => {
      console.log("LoginFail");
      setLoginFail(true);
    });
    socket.on("LoginSuccess", (data) => {
      console.log("succecss", data);
      setUserToken(data.mb_token);
      setUserName(data.info.mb_name);
      setTerms(true);
    });
    setWhere("login");
    return () => {
      socket.off("LoginFail");
      socket.off("LoginSuccess");
    };
  }, [setWhere, socket]);

  return (
    <div className={style.LoginPage}>
      <div className={style.inPage}>
        {LoginFail ? (
          <FailPage
            onResetFail={() => setLoginFail(false)}
            language={language}
          />
        ) : !Terms ? (
          <div className={style.form_container}>
            <div className={style["img-box"]}>
              <img src="./icon.png" alt="" />
            </div>
            <div className={style.language_class}>
              <button type="button" id="btn_language" onClick={toggleDropdown}>
                <div id="select_lang">{languageDisplay[language]}</div>
                <span></span>
              </button>
              <div
                className={`${style.select} ${
                  langDropdownOpen ? style.active : ""
                }`}
                id="select"
              >
                <div onClick={() => changeLangCookie("ko")}>
                  <img src="./language_ko.jpg" alt="Korean" /> KOREAN
                </div>
                <div onClick={() => changeLangCookie("cn")}>
                  <img src="./language_ch.jpg" alt="Chinese" /> CHINESE
                </div>
                <div onClick={() => changeLangCookie("en")}>
                  <img src="./language_en.jpg" alt="English" /> ENGLISH
                </div>
                <div onClick={() => changeLangCookie("jp")}>
                  <img src="./language_jp.jpg" alt="Japanese" /> JAPANESE
                </div>
              </div>
            </div>
            <form onSubmit={handleSubmit}>
              <div className={style["input-field"]}>
                <input
                  type="text"
                  placeholder={getTranslation(language, "id")}
                  name="user_id"
                  id="user_id"
                  autoComplete="off"
                  value={userId}
                  onChange={(e) => setUserId(e.target.value)}
                />
              </div>
              <div className={style["input-field"]}>
                <input
                  type="password"
                  placeholder={getTranslation(language, "pw")}
                  name="user_pw"
                  id="user_pw"
                  autoComplete="off"
                  value={userPw}
                  onChange={(e) => setUserPw(e.target.value)}
                />
              </div>
              <div className={style["input-field"]}>
                <input
                  type="submit"
                  value={getTranslation(language, "login")}
                  id="login_submit"
                />
              </div>
            </form>
            {false && (
              <div className={style["input-field"]}>
                <button id="login_guest" onClick={handleGuestLogin}>
                  {getTranslation(language, "guest_login")}
                </button>
              </div>
            )}
          </div>
        ) : (
          <div className={style["form_container2"]}>
            <div className={style.agree_title}>
              {getTranslation(language, "terms_title")}
            </div>
            <div className={style.form_container2_box}>
              <div className={`${style.i_list} ${style.agree_title}`}>
                {paragraphs.map((para, idx) => (
                  <p key={idx}>{para}</p>
                ))}
              </div>
            </div>
            <div className={style.i_btn}>
              <div className={style["input-field"]}>
                <button onClick={() => setTerms(false)}>
                  <span className={style.agree_cancel}>
                    {getTranslation(language, "cancel")}
                  </span>
                </button>
              </div>
              <div className={style["input-field"]}>
                <button onClick={() => GoLobby()}>
                  <span className={style.agree_ok}>
                    {getTranslation(language, "login")}
                  </span>
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default LoginPage;
