// App.js
import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../store";
import style from "./GamePage.module.css";
import { BigRoad } from "../roads/BigLoad.ts";
import { BigEyeRoad } from "../roads/BigEyeRoad.ts";
import { SmallRoad } from "../roads/SmallRoad.ts";
import { CockroachRoad } from "../roads/CockroachRoad.ts";
import {
  ConvertCard,
  getTranslation,
  moneyformatNumber,
  SevUrl,
} from "../Utility.js";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../SocketContext.js";
function GamePage() {
  const socket = useSocket();
  const navigate = useNavigate();
  const {
    // ExPlayerGun2Mark,
    // ExPlayerGun3Mark,
    // ExPlayerGun4Mark,
    // ExBankerGun2Mark,
    // ExBankerGun3Mark,
    // ExBankerGun4Mark,
    r_room,
    s_room,

    setRoom_s,
    setRoom_r,

    score_room,
    setScore_room,
    gun1,
    setGun1,
    gun2,
    setGun2,
    gun3,
    setGun3,
    gun4,
    setGun4,
    language,

    RoomID,
    setRoomID,
    id,
    setId,
    Where,
    setWhere,
  } = useStore();

  const [isFullscreen, setIsFullscreen] = useState(false);

  const row = 6;
  const PoolCol = 18;
  const Gun1Col = 114;
  const Gun2Col = 19;
  const Gun3Col = 19;
  const Gun4Col = 19;

  const [add, setAdd] = useState(false);
  const latestRoomDataRef = useRef(null);
  const [move, setMove] = useState(false);

  // Result Section var
  const [ScoreP, setScoreP] = useState(0);
  const [CardP, setCardP] = useState([]);
  const [winner, setWinner] = useState("");
  const [ScoreB, setScoreB] = useState(0);
  const [CardB, setCardB] = useState([]);

  const containerRef = useRef(null);
  // 화면 크기 변경 시 적용될 스케일 계산 함수
  const calculateScale = () => {
    if (!containerRef.current) return;

    // 현재 브라우저 창 크기
    const viewportWidth = window.innerWidth;
    const viewportHeight = window.innerHeight;

    // 디자인 기준 크기
    const containerWidth = 1920;
    const containerHeight = 1080;

    // 가로 및 세로 비율 계산
    const scaleX = viewportWidth / containerWidth;
    const scaleY = viewportHeight / containerHeight;

    // 가장 작은 스케일 값을 선택하여 컨텐츠가 모두 보이도록 함
    const newScale = Math.min(scaleX, scaleY) * 0.95; // 5% 여백 추가

    setScale(newScale);

    // 콘솔에 로그 출력 (디버깅용)
    // console.log(
    //   `Viewport: ${viewportWidth}x${viewportHeight}, Scale: ${newScale}`
    // );
  };

  function gun_click(type) {
    if (add) return;
    setAdd(true);

    const newData = [
      ...r_room[id],
      type === "Player"
        ? {
            sBanker_Score: "0",
            sPlayer_Score: "7",
            sWinner: "Player",
            sPair: "",
          }
        : {
            sBanker_Score: "7",
            sPlayer_Score: "0",
            sWinner: "Banker",
            sPair: "",
          },
    ];

    setRoom_r(id, newData);
    GunSet(id, newData);

    setTimeout(() => {
      const latestData = latestRoomDataRef.current || r_room[id];
      GunSet(id, latestData);
      setRoom_r(id, latestData);
      latestData.current = null;
      setAdd(false);
    }, 2000);
  }
  // 전체화면 모드 진입
  const enterFullscreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
          console.log("Entered fullscreen mode");
          // 전체화면 모드 후 스케일 재계산
          setTimeout(calculateScale, 100);
        })
        .catch((err) => {
          console.error("Error entering fullscreen", err);
        });
    } else {
      alert("전체화면 모드가 지원되지 않습니다.");
    }
  };
  // 전체화면 모드 종료
  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullscreen(false);
          console.log("Exited fullscreen mode");
          // 전체화면 모드 종료 후 스케일 재계산
          setTimeout(calculateScale, 100);
        })
        .catch((err) => {
          console.error("Error exiting fullscreen", err);
        });
    }
  };
  const Expect = React.useCallback(
    (r_room) => {
      let pg1;

      const newProom = [
        ...r_room,
        {
          sBanker_Score: "0",
          sPlayer_Score: "7",
          sWinner: "Player",
          sPair: "",
        },
      ];

      let plog = newProom;
      let pFirstTie = 0;
      if (plog.length > 0) {
        for (let i = 0; i < Math.min(8, plog.length); i++) {
          if (plog[i].sWinner === "Tie") {
            pFirstTie++;
          } else {
            break;
          }
        }
      }

      plog = plog.slice(pFirstTie, plog.length);
      pg1 = new BigRoad({
        array: plog,
        row: row,
        col: Gun1Col,
        cut: true,
      }).getBigRoad();

      new BigEyeRoad({
        gubun: pg1.gubunIndex,
        row: row,
        col: Gun2Col * 2,
        cut: true,
      }).getBigEyeRoad(pg1.old, true, "Player");

      new SmallRoad({
        gubun: pg1.gubunIndex,
        row: row,
        col: Gun3Col * 2,
        cut: true,
      }).getSmallRoad(pg1.old, true, "Player");

      new CockroachRoad({
        gubun: pg1.gubunIndex,
        row: row,
        col: Gun4Col * 2,
        cut: true,
      }).getCockroachRoad(pg1.old, true, "Player");
      ////////////////////
      let bg1;
      const newBroom = [
        ...r_room,
        {
          sBanker_Score: "7",
          sPlayer_Score: "0",
          sWinner: "Banker",
          sPair: "",
        },
      ];
      let blog = newBroom;
      let bFirstTie = 0;
      if (blog.length > 0) {
        for (let i = 0; i < Math.min(8, blog.length); i++) {
          if (blog[i].sWinner === "Tie") {
            bFirstTie++;
          } else {
            break;
          }
        }
      }
      blog = blog.slice(bFirstTie, blog.length);
      bg1 = new BigRoad({
        array: blog,
        row: row,
        col: Gun1Col,
        cut: true,
      }).getBigRoad();

      new BigEyeRoad({
        gubun: bg1.gubunIndex,
        row: row,
        col: Gun2Col * 2,
        cut: true,
      }).getBigEyeRoad(bg1.old, true, "Banker");

      new SmallRoad({
        gubun: bg1.gubunIndex,
        row: row,
        col: Gun3Col * 2,
        cut: true,
      }).getSmallRoad(bg1.old, true, "Banker");

      new CockroachRoad({
        gubun: bg1.gubunIndex,
        row: row,
        col: Gun4Col * 2,
        cut: true,
      }).getCockroachRoad(bg1.old, true, "Banker");
    },
    [Gun1Col, Gun2Col, Gun3Col, Gun4Col]
  );
  const ScoreSet = React.useCallback(
    (roomIndex, r_room) => {
      let copyScore;
      if (r_room.length === 0) {
        copyScore = [0, 0, 0, 0, 0];
      } else {
        let bankerScore = 0;
        let playerScore = 0;
        let tieScore = 0;
        let bPScore = 0;
        let pPScore = 0;
        for (let i = 0; i < r_room.length; i++) {
          switch (r_room[i].sWinner) {
            case "Banker":
              bankerScore += 1;
              break;
            case "Player":
              playerScore += 1;
              break;
            case "Tie":
              tieScore += 1;
              break;
            default:
              console.warn(`Unexpected sWinner value: ${r_room[i]}`);
              break;
          }
          switch (r_room[i].sPair) {
            case "Banker_Pair":
              bPScore += 1;
              break;
            case "Player_Pair":
              pPScore += 1;
              break;
            case "Player_Pair,Banker_Pair":
            case "Banker_Pair,Player_Pair":
              pPScore += 1;
              bPScore += 1;
              break;
            default:
              break;
          }
        }
        copyScore = [bankerScore, playerScore, tieScore, bPScore, pPScore];
      }

      setScore_room(roomIndex, copyScore);
    },
    [setScore_room]
  );
  const GunSet = React.useCallback(
    (roomIndex, r_room, inGame = false, type = "") => {
      let g1, g2, g3, g4;

      if (r_room.length === 0) {
        g1 = {};
        g2 = {};
        g3 = {};
        g4 = {};
      } else {
        let log = r_room;
        let FirstTie = 0;
        if (log.length > 0) {
          for (let i = 0; i < Math.min(8, log.length); i++) {
            if (log[i].sWinner === "Tie") {
              FirstTie++;
            } else {
              break;
            }
          }
        }

        log = log.slice(FirstTie, log.length);
        g1 = new BigRoad({
          array: log,
          row: row,
          col: Gun1Col,
          cut: true,
        }).getBigRoad();

        g2 = new BigEyeRoad({
          gubun: g1.gubunIndex,
          row: row,
          col: Gun2Col * 2,
          cut: true,
        }).getBigEyeRoad(g1.old, inGame, type);

        g3 = new SmallRoad({
          gubun: g1.gubunIndex,
          row: row,
          col: Gun3Col * 2,
          cut: true,
        }).getSmallRoad(g1.old, inGame, type);

        g4 = new CockroachRoad({
          gubun: g1.gubunIndex,
          row: row,
          col: Gun4Col * 2,
          cut: true,
        }).getCockroachRoad(g1.old, inGame, type);
      }

      setGun1(roomIndex, g1);
      setGun2(roomIndex, g2);
      setGun3(roomIndex, g3);
      setGun4(roomIndex, g4);
    },
    [Gun1Col, setGun1, setGun2, setGun3, setGun4]
  );
  useEffect(() => {
    const sliders = document.querySelectorAll(
      ".scrollable, .scrollable-gun1, .scrollable-gun2, .scrollable-gun3, .scrollable-gun4"
    );
    sliders.forEach((slider) => {
      let isDown = false;
      let startX;
      let scrollLeft;

      const mouseDownHandler = (e) => {
        isDown = true;
        startX = e.pageX - slider.offsetLeft;
        scrollLeft = slider.scrollLeft;
        slider.classList.add("active");
      };

      const mouseLeaveHandler = () => {
        isDown = false;
        slider.classList.remove("active");
      };

      const mouseUpHandler = () => {
        isDown = false;
        slider.classList.remove("active");
      };

      const mouseMoveHandler = (e) => {
        if (!isDown) return;
        e.preventDefault();
        const x = e.pageX - slider.offsetLeft;
        const walk = (x - startX) * 3;
        slider.scrollLeft = scrollLeft - walk;
      };

      slider.addEventListener("mousedown", mouseDownHandler);
      slider.addEventListener("mouseleave", mouseLeaveHandler);
      slider.addEventListener("mouseup", mouseUpHandler);
      slider.addEventListener("mousemove", mouseMoveHandler);

      // cleanup for each slider
      slider.cleanupScroll = () => {
        slider.removeEventListener("mousedown", mouseDownHandler);
        slider.removeEventListener("mouseleave", mouseLeaveHandler);
        slider.removeEventListener("mouseup", mouseUpHandler);
        slider.removeEventListener("mousemove", mouseMoveHandler);
      };
    });

    return () => {
      sliders.forEach(
        (slider) => slider.cleanupScroll && slider.cleanupScroll()
      );
    };
  }, []);
  const [scale, setScale] = useState(1);

  useEffect(() => {
    // 초기 렌더링 시 스케일 계산
    calculateScale();

    // 화면 크기 변경 이벤트 리스너 등록
    window.addEventListener("resize", calculateScale);

    // ResizeObserver를 사용하여 컨테이너 크기 변경 감지
    let resizeObserver = null;
    if (containerRef.current) {
      resizeObserver = new ResizeObserver(() => {
        calculateScale();
      });
      resizeObserver.observe(containerRef.current);
    }

    if (!socket) return;

    if (socket.connected && Where === "lobby") {
      setWhere("game");

      Expect(r_room[id]);
    }

    socket.on("statusLobby", (data) => {
      console.log("Received statusLobby:", data);

      const roomIndex = s_room.findIndex((el) => el.RoomID === data.RoomID);
      if (roomIndex === -1) {
        return;
      }

      if (data.status === "Open" || data.status === "Close") {
        const updatedRoom = {
          ...s_room[roomIndex],
          active: data.status === "Open" ? 1 : 0,
          status: data.status.split("-")[0],
        };
        setRoom_s(roomIndex, updatedRoom);
      } else {
        const updatedRoom = {
          ...s_room[roomIndex],
          status: data.status.split("-")[0],
        };
        // Update the state with the new array
        setRoom_s(roomIndex, updatedRoom);
      }
    });

    socket.on("enterSingleLobby", (data) => {
      // console.log("enterSingleLobby", data);

      const roomIndex = s_room.findIndex((el) => el.RoomID === data.RoomID);
      if (roomIndex === -1) {
        return;
      }
      if (!Array.isArray(data.result)) {
        console.error("data.result is not an array:", data.result);
        return;
      }

      setRoom_s(roomIndex, data.s_room);
      setRoom_r(roomIndex, data.result);
      if (roomIndex === id) {
        Expect(data.result);
        latestRoomDataRef.current = data.result;
      }
      //Score 업데이트
      ScoreSet(roomIndex, data.result);
      GunSet(roomIndex, data.result);
    });

    socket.on("Game_Start", (data) => {
      // console.log("Game_Start", data);
      setScoreB(0);
      setScoreP(0);
      setWinner("");
      setCardB([]);
      setCardP([]);
    });
    socket.on("resultWinner", (data) => {
      console.log("resultWinner", data);
      setScoreB(data.BankerScore);
      setScoreP(data.PlayerScore);
      setWinner(data.who);
      setCardB(data.bCard);
      setCardP(data.pCard);
    });

    setWhere("game");
    // 컴포넌트 언마운트 시 소켓 이벤트 정리
    return () => {
      window.removeEventListener("resize", calculateScale);
      if (resizeObserver) {
        resizeObserver.disconnect();
      }

      socket.off("statusLobby");
      socket.off("enterSingleLobby");
      socket.off("Game_Start");
      socket.off("resultWinner");
    };
  }, [
    Expect,
    GunSet,
    ScoreSet,
    Where,
    id,
    r_room,
    s_room,
    setRoom_r,
    setRoom_s,
    setWhere,
    socket,
  ]);
  //Function Section

  function Pool(index) {
    const roomIndex = s_room.findIndex((el) => el.RoomID === RoomID);

    if (!r_room[roomIndex] || !Array.isArray(r_room[roomIndex])) {
      return "";
    }

    const PoolShowTotal = 66;
    const PoolEmpty = 6;
    const PoolShowOver = 60;

    let diffLength = 0;
    let PoolData = [];

    if (r_room[roomIndex].length <= PoolShowOver) {
      PoolData = r_room[roomIndex];
    } else {
      diffLength = PoolShowTotal - r_room[roomIndex].length;
      const reLength =
        diffLength === 1
          ? -36
          : (((r_room[roomIndex].length % PoolShowTotal) % PoolEmpty) +
              PoolShowOver) *
            -1;

      PoolData = r_room[roomIndex].slice(reLength);
    }

    if (PoolData.length === 0 || !PoolData[index]) return "";

    let fullName = "";
    switch (PoolData[index].sWinner) {
      case "Player":
        fullName += "p";
        break;
      case "Tie":
        fullName += "t";
        break;
      case "Banker":
        fullName += "b";
        break;
      default:
        fullName = "";
        break;
    }
    if (fullName !== "") {
      switch (PoolData[index].sPair) {
        case "Banker_Pair":
          fullName += "2";
          break;
        case "Player_Pair":
          fullName += "3";
          break;
        case "Player_Pair,Banker_Pair":
          fullName += "4";
          break;
        default:
          fullName += "1";
          break;
      }
    }
    return fullName === "" || !fullName ? "" : fullName;
  }

  function Gun1(index, item = null) {
    const roomIndex = s_room.findIndex(
      (el) => el.RoomID === (item ? item.RoomID : RoomID)
    );

    if (
      !r_room ||
      !r_room[roomIndex] ||
      r_room[roomIndex].length === 0 ||
      !Array.isArray(r_room[roomIndex]) ||
      !gun1[roomIndex] ||
      gun1[roomIndex].length === 0
    ) {
      return "";
    }

    let mod = Math.floor(index / (item ? Gun1Col / 2 : Gun1Col)); //몫
    let namuzi = index % (item ? Gun1Col / 2 : Gun1Col); //나머지

    if (!gun1[roomIndex].new[mod][namuzi]) {
      return "";
    }

    const reName = gun1[roomIndex].new[mod][namuzi];

    if (reName.Win === "") {
      return "";
    } else {
      let name = reName.Win === "Player" ? "b" : "r";
      let num =
        reName.Pair === 3
          ? "4"
          : reName.Pair === 2
          ? "2"
          : reName.Pair === 1
          ? "3"
          : "1";

      if (reName.TieCount !== 0) {
        num += "t";
      }

      return name + num;
    }
  }
  function Gun1Tie(index, item = null) {
    const roomIndex = s_room.findIndex(
      (el) => el.RoomID === (item ? item.RoomID : RoomID)
    );
    if (
      !r_room ||
      !r_room[roomIndex] ||
      r_room[roomIndex].length === 0 ||
      !Array.isArray(r_room[roomIndex]) ||
      !gun1[roomIndex] ||
      gun1[roomIndex].length === 0
    ) {
      return "";
    }

    let mod = Math.floor(index / (item ? Gun1Col / 2 : Gun1Col)); //몫
    let namuzi = index % (item ? Gun1Col / 2 : Gun1Col); //나머지
    const reName = gun1[roomIndex].new[mod][namuzi];

    if (reName !== "" && reName.TieCount !== 0) {
      return reName.TieCount;
    }
    return "";
  }
  function Gun2(index, jndex, item = null) {
    const roomIndex = s_room.findIndex(
      (el) => el.RoomID === (item ? item.RoomID : RoomID)
    );
    if (
      !r_room ||
      !r_room[roomIndex] ||
      r_room[roomIndex].length === 0 ||
      !Array.isArray(r_room[roomIndex]) ||
      !gun2[roomIndex] ||
      gun2[roomIndex].length === 0
    ) {
      return "";
    }

    const reName = gun2[roomIndex][index][jndex];

    if (reName.Mark === "") {
      return "";
    } else {
      return reName.Mark === "Player" ? "b" : "r";
    }
  }
  function Gun3(index, jndex, item = null) {
    const roomIndex = s_room.findIndex(
      (el) => el.RoomID === (item ? item.RoomID : RoomID)
    );
    if (
      !r_room ||
      !r_room[roomIndex] ||
      r_room[roomIndex].length === 0 ||
      !Array.isArray(r_room[roomIndex]) ||
      !gun3[roomIndex] ||
      gun3[roomIndex].length === 0
    ) {
      return "";
    }

    const reName = gun3[roomIndex][index][jndex];

    if (reName.Mark === "") {
      return "";
    } else {
      return reName.Mark === "Player" ? "b" : "r";
    }
  }
  function Gun4(index, jndex, item = null) {
    const roomIndex = s_room.findIndex(
      (el) => el.RoomID === (item ? item.RoomID : RoomID)
    );
    if (
      !r_room ||
      !r_room[roomIndex] ||
      r_room[roomIndex].length === 0 ||
      !Array.isArray(r_room[roomIndex]) ||
      !gun4[roomIndex] ||
      gun4[roomIndex].length === 0
    ) {
      return "";
    }

    const reName = gun4[roomIndex][index][jndex];

    if (reName.Mark === "") {
      return "";
    } else {
      return reName.Mark === "Player" ? "b" : "r";
    }
  }
  function Scoreing(index) {
    const roomIndex = s_room.findIndex((el) => el.RoomID === RoomID);
    if (
      !score_room ||
      !score_room[roomIndex] ||
      score_room[roomIndex].length === 0 ||
      !Array.isArray(score_room[roomIndex])
    ) {
      return 0;
    }

    return score_room[roomIndex][index];
  }
  function quickOption(type) {
    switch (type) {
      case "move":
        setMove(true);
        break;

      case "lobby":
        setRoomID("");
        setId(0);
        setMove(false);
        navigate("/lobby");
        break;
      case "refresh":
        // window.location.reload();
        break;
      default:
        console.log("default");
        break;
    }
  }
  function moveTable(data) {
    if (RoomID === data.RoomID) {
      return;
    }

    setRoomID(data.RoomID);
    setId(s_room.findIndex((el) => el.RoomID === data.RoomID));
    setMove(false);
  }
  return (
    <div className={style.GamePage}>
      <div className={style.inPage}>
        <div className={style.inPageContainer}>
          <div
            className={style.container}
            ref={containerRef}
            style={{
              position: "absolute",
              top: "55%",
              left: "50%",
              transform: `translate(-50%, -50%) scale(${scale})`,
              transformOrigin: "center center",
              paddingTop: "0",
              paddingBottom: "0",
              overflow: "hidden",
            }}
          >
            <div className={style.top}>
              <div className={style.videos} />
              <div className={style.infos}>
                <div className={style.logNumber}>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div></div>
                  <div className={style[`${SevUrl().listName}logo`]}></div>
                  <div className={style.title}>
                    {getTranslation(language, "game")}
                  </div>
                  <div className={style.Number}>
                    {s_room[id]?.RoomNumber || ""}
                  </div>
                  <div className={style.arrow}></div>
                </div>
                <div className={style.notice}>
                  <div className={style.marquee}>
                    <span id="txt_welcome">
                      {getTranslation(language, "welcome")}
                    </span>
                  </div>
                </div>

                <div className={style.welcome}>
                  <div className={style["bet-limits"]}>
                    <div className={style["limits-column"]}>
                      <div className={style["limit-header"]}>
                        {getTranslation(language, "minimum")}
                      </div>
                      <div className={style["limit-item"]}>
                        <span className={style["txt-blue"]}>Player</span>
                        <span className={style["limit-value"]}>
                          {moneyformatNumber(
                            s_room[id]?.LimitBet?.KRW?.min || 0
                          )}
                        </span>
                      </div>
                      <div className={style["limit-item"]}>
                        <span className={style["txt-yellow"]}>Banker</span>
                        <span className={style["limit-value"]}>
                          {moneyformatNumber(
                            s_room[id]?.LimitBet?.KRW?.min || 0
                          )}
                        </span>
                      </div>
                      <div className={style["limit-item"]}>
                        <span className={style["txt-green"]}>Tie</span>
                        <span className={style["limit-value"]}>
                          {moneyformatNumber(
                            s_room[id]?.LimitBet?.KRW?.min_tie || 0
                          )}
                        </span>
                      </div>
                      <div className={style["limit-item"]}>
                        <span className={style["txt-skyblue"]}>P.Pair</span>
                        <span className={style["limit-value"]}>
                          {moneyformatNumber(
                            s_room[id]?.LimitBet?.KRW?.min_pair || 0
                          )}
                        </span>
                      </div>
                      <div className={style["limit-item"]}>
                        <span className={style["txt-pink"]}>B.Pair</span>
                        <span className={style["limit-value"]}>
                          {moneyformatNumber(
                            s_room[id]?.LimitBet?.KRW?.min_pair || 0
                          )}
                        </span>
                      </div>
                    </div>
                    <div className={style["limits-column"]}>
                      <div className={style["limit-header"]}>
                        {getTranslation(language, "maximum")}
                      </div>
                      <div className={style["limit-item"]}>
                        <span className={style["txt-blue"]}>Player</span>
                        <span className={style["limit-value"]}>
                          {moneyformatNumber(
                            s_room[id]?.LimitBet?.KRW?.max || 0
                          )}
                        </span>
                      </div>
                      <div className={style["limit-item"]}>
                        <span className={style["txt-yellow"]}>Banker</span>
                        <span className={style["limit-value"]}>
                          {moneyformatNumber(
                            s_room[id]?.LimitBet?.KRW?.max || 0
                          )}
                        </span>
                      </div>
                      <div className={style["limit-item"]}>
                        <span className={style["txt-green"]}>Tie</span>
                        <span className={style["limit-value"]}>
                          {moneyformatNumber(
                            s_room[id]?.LimitBet?.KRW?.max_tie || 0
                          )}
                        </span>
                      </div>
                      <div className={style["limit-item"]}>
                        <span className={style["txt-skyblue"]}>P.Pair</span>
                        <span className={style["limit-value"]}>
                          {moneyformatNumber(
                            s_room[id]?.LimitBet?.KRW?.max_pair || 0
                          )}
                        </span>
                      </div>
                      <div className={style["limit-item"]}>
                        <span className={style["txt-pink"]}>B.Pair</span>
                        <span className={style["limit-value"]}>
                          {moneyformatNumber(
                            s_room[id]?.LimitBet?.KRW?.max_pair || 0
                          )}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className={style["sys-quick-btn"]}>
                  <div className={style["quick-btn"]}>
                    <div className={style.move}>
                      <button onClick={() => quickOption("move")}>
                        <span className={style.t_btn_table_move}>
                          {getTranslation(language, "tableMove")}
                        </span>
                      </button>
                    </div>
                    <div className={style.fullscreen}>
                      <button
                        onClick={() =>
                          isFullscreen ? exitFullscreen() : enterFullscreen()
                        }
                      >
                        <span className={style.t_btn_full}>
                          {getTranslation(
                            language,
                            isFullscreen ? "exitFullscreen" : "fullscreen"
                          )}
                        </span>
                      </button>
                    </div>
                    <div className={style.exit}>
                      <button onClick={() => quickOption("lobby")}>
                        <span className={style.t_btn_prev}>
                          {getTranslation(language, "lobbyMove")}
                        </span>
                      </button>
                    </div>
                    <div className={style.re}>
                      <button onClick={() => quickOption("refresh")}>
                        <span className={style.t_btn_chip_refresh}>
                          {getTranslation(language, "refresh")}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className={style.rooms}>
              <div className={style.mid}>
                <div className={style.board}>
                  {/* 왼쪽 그리드: 6x6 셀 */}
                  <div className={style["Pool-left"]}>
                    <div className={style["fixed-overlay"]}></div>
                    <div className={style.scrollable}>
                      {Array.from({ length: row * PoolCol }).map((_, index) => (
                        <div
                          key={index}
                          className={`${style.cell} ${style[Pool(index)]}`}
                        ></div>
                      ))}
                    </div>
                  </div>
                  {/* 오른쪽 그리드: 18x12 셀 */}
                  <div className={style["Gun-right"]}>
                    <div className={style["Gun1-container"]}>
                      <div className={style["fixed-overlay-gun1"]}></div>
                      <div className={style["scrollable-gun1"]}>
                        {Array.from({ length: row * Gun1Col }).map(
                          (_, index) => (
                            <div
                              key={index}
                              className={`${style.cell} ${
                                style[Gun1(index)]
                              } ${index}`}
                            >
                              {Gun1Tie(index)}
                            </div>
                          )
                        )}
                      </div>
                    </div>

                    <div className={style.gun234}>
                      <div className={style["Gun2-container"]}>
                        <div className={style["fixed-overlay-gun2"]}></div>
                        <div className={style["scrollable-gun2"]}>
                          {Array.from({ length: (row / 2) * Gun2Col }).map(
                            (_, index) => (
                              <div key={index} className={style.cell}>
                                {/* 왼쪽상단 */}
                                <div
                                  className={`${
                                    style[
                                      Gun2(
                                        Math.floor(index / Gun2Col) * 2,
                                        (index % Gun2Col) * 2
                                      )
                                    ]
                                  }`}
                                ></div>
                                {/* 오른쪽상단 */}
                                <div
                                  className={`${
                                    style[
                                      Gun2(
                                        Math.floor(index / Gun2Col) * 2,
                                        (index % Gun2Col) * 2 + 1
                                      )
                                    ]
                                  }`}
                                ></div>
                                {/* 왼쪽하단 */}
                                <div
                                  className={`${
                                    style[
                                      Gun2(
                                        Math.floor(index / Gun2Col) * 2 + 1,
                                        (index % Gun2Col) * 2
                                      )
                                    ]
                                  }`}
                                ></div>
                                {/* 오른쪽하단 */}
                                <div
                                  className={`${
                                    style[
                                      Gun2(
                                        Math.floor(index / Gun2Col) * 2 + 1,
                                        (index % Gun2Col) * 2 + 1
                                      )
                                    ]
                                  }`}
                                ></div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className={style["Gun3-container"]}>
                        <div className={style["fixed-overlay-gun3"]}></div>
                        <div className={style["scrollable-gun3"]}>
                          {Array.from({ length: (row / 2) * Gun3Col }).map(
                            (_, index) => (
                              <div key={index} className={style.cell}>
                                {/* 왼쪽상단 */}
                                <div
                                  className={`${
                                    style[
                                      Gun3(
                                        Math.floor(index / Gun3Col) * 2,
                                        (index % Gun3Col) * 2
                                      )
                                    ]
                                  }`}
                                ></div>
                                {/* 오른쪽상단 */}
                                <div
                                  className={`${
                                    style[
                                      Gun3(
                                        Math.floor(index / Gun3Col) * 2,
                                        (index % Gun3Col) * 2 + 1
                                      )
                                    ]
                                  }`}
                                ></div>
                                {/* 왼쪽하단 */}
                                <div
                                  className={`${
                                    style[
                                      Gun3(
                                        Math.floor(index / Gun3Col) * 2 + 1,
                                        (index % Gun3Col) * 2
                                      )
                                    ]
                                  }`}
                                ></div>
                                {/* 오른쪽하단 */}
                                <div
                                  className={`${
                                    style[
                                      Gun3(
                                        Math.floor(index / Gun3Col) * 2 + 1,
                                        (index % Gun3Col) * 2 + 1
                                      )
                                    ]
                                  }`}
                                ></div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                      <div className={style["Gun4-container"]}>
                        <div className={style["fixed-overlay-gun4"]}></div>
                        <div className={style["scrollable-gun4"]}>
                          {Array.from({ length: (row / 2) * Gun4Col }).map(
                            (_, index) => (
                              <div key={index} className={style.cell}>
                                {/* 왼쪽상단 */}
                                <div
                                  className={`${
                                    style[
                                      Gun4(
                                        Math.floor(index / Gun4Col) * 2,
                                        (index % Gun4Col) * 2
                                      )
                                    ]
                                  }`}
                                ></div>
                                {/* 오른쪽상단 */}
                                <div
                                  className={`${
                                    style[
                                      Gun4(
                                        Math.floor(index / Gun4Col) * 2,
                                        (index % Gun4Col) * 2 + 1
                                      )
                                    ]
                                  }`}
                                ></div>
                                {/* 왼쪽하단 */}
                                <div
                                  className={`${
                                    style[
                                      Gun4(
                                        Math.floor(index / Gun4Col) * 2 + 1,
                                        (index % Gun4Col) * 2
                                      )
                                    ]
                                  }`}
                                ></div>
                                {/* 오른쪽하단 */}
                                <div
                                  className={`${
                                    style[
                                      Gun4(
                                        Math.floor(index / Gun4Col) * 2 + 1,
                                        (index % Gun4Col) * 2 + 1
                                      )
                                    ]
                                  }`}
                                ></div>
                              </div>
                            )
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={style.bottom}>
                <div className={style.left}>
                  <div className={`${style.icon} ${style.red}`}></div>
                  <div className={style.score}>{Scoreing(0)}</div>

                  <div className={`${style.icon} ${style.blue}`}></div>
                  <div className={style.score}>{Scoreing(1)}</div>

                  <div className={`${style.icon} ${style.green}`}></div>
                  <div className={style.score}>{Scoreing(2)}</div>

                  <div className={`${style.icon} ${style.redP}`}></div>
                  <div className={style.score}>{Scoreing(3)}</div>

                  <div className={`${style.icon} ${style.blueP}`}></div>
                  <div className={style.score}>{Scoreing(4)}</div>
                </div>
                <div className={style.right}>
                  <button onClick={() => gun_click("Banker")}>
                    <div className={style.red}></div>
                    {/* <div className={`g2${ExBankerGun2Mark}`}></div> */}
                    <div className={style.g2Banker}></div>
                    <div className={style.g3Banker}></div>
                    <div className={style.g4Banker}></div>
                  </button>
                  <button onClick={() => gun_click("Player")}>
                    <div className={style.blue}></div>
                    {/* <div className={`g2${ExPlayerGun2Mark}`}></div> */}
                    <div className={style.g2Player}></div>
                    <div className={style.g3Player}></div>
                    <div className={style.g4Player}></div>
                  </button>
                </div>
              </div>
            </div>
            {move && (
              <div className={style.MoveTableList}>
                <div className={style.titleS}>
                  <div>{getTranslation(language, "tableMove")}</div>
                  <span
                    onClick={() => {
                      setMove(false);
                    }}
                  >
                    X
                  </span>
                </div>
                <div className={style.mRooms}>
                  {s_room.map(
                    (item, index) =>
                      item.active === 1 && (
                        <div key={index} onClick={() => moveTable(item)}>
                          <div className={style.title}>
                            <div
                              className={style[`${SevUrl().listName}logo`]}
                            ></div>
                            <div className={style.roomInfo}>
                              <div className={style.Number}>
                                {s_room[index]?.RoomNumber || ""}
                              </div>
                              <div className={style.status}>
                                {/* {item.status.split("-")[0]} */}
                                Playing
                              </div>
                            </div>
                          </div>
                          <div className={style.gun1}>
                            {/* Gun1Setting + Scroll */}
                            {Array.from({ length: (Gun1Col / 2) * 6 }).map(
                              (_, index) => (
                                <div
                                  key={index}
                                  className={`${style.cell} ${
                                    style[Gun1(index, item)]
                                  }`}
                                >
                                  {Gun1Tie(index, item)}
                                </div>
                              )
                            )}
                          </div>
                        </div>
                      )
                  )}
                </div>
              </div>
            )}
            {winner !== "" && (
              <div className={style.Result}>
                <div className={style.dummy}></div>
                <div className={style.Container}>
                  <div className={style.stick}></div>
                  <div className={style.PScore}>{ScoreP}</div>
                  <div className={style.BScore}>{ScoreB}</div>
                  <div className={style.Player}>
                    {CardP.map((item, i) => (
                      <div
                        key={i}
                        className={`${style[`Pcard${i}`]} ${
                          style[ConvertCard(item.Num, item.Shape)]
                        }`}
                      ></div>
                    ))}
                  </div>
                  <div className={`${style[`win${winner}`]}`}></div>
                  <div className={style.Banker}>
                    {CardB.map((item, i) => (
                      <div
                        key={i}
                        className={`${style[`Bcard${i}`]} ${
                          style[ConvertCard(item.Num, item.Shape)]
                        }`}
                      ></div>
                    ))}
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default GamePage;
