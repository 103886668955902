import React, { useEffect, useRef, useState } from "react";
import { useStore } from "../store";
import style from "./LobbyPage.module.css";
import { BigRoad } from "../roads/BigLoad.ts";
import { BigEyeRoad } from "../roads/BigEyeRoad.ts";
import { SmallRoad } from "../roads/SmallRoad.ts";
import { CockroachRoad } from "../roads/CockroachRoad.ts";
import { getTranslation, SevUrl } from "../Utility.js";
import { useNavigate } from "react-router-dom";
import { useSocket } from "../SocketContext.js";

function LobbyPage() {
  const socket = useSocket();
  const navigate = useNavigate();
  const {
    setRoomID,

    mb_name,
    r_room,
    s_room,
    setRoom_s,
    setRoom_r,
    score_room,
    setScore_room,
    gun1,
    setGun1,
    gun2,
    setGun2,
    gun3,
    setGun3,
    gun4,
    setGun4,
    language,
    setLanguage,

    setToken,
    setMb_name,
    setId,
    Where,
    setWhere,
  } = useStore();

  const Gun1Cols = 21;
  const Gun2Cols = 21;
  const Gun3Cols = 11;
  const Gun4Cols = 10;
  const Row = 6;
  const reEl = <div></div>;

  const [isFullscreen, setIsFullscreen] = useState(false);
  const chLang = (lang) => {
    setLanguage(lang);
  };
  const openGameInfo = () => {
    alert("게임방법 안내:\n여기에 게임 방법에 대한 설명을 추가하세요.");
  };

  // 전체화면 모드 진입
  const enterFullscreen = () => {
    if (document.documentElement.requestFullscreen) {
      document.documentElement
        .requestFullscreen()
        .then(() => {
          setIsFullscreen(true);
          console.log("Entered fullscreen mode");
        })
        .catch((err) => {
          console.error("Error entering fullscreen", err);
        });
    } else {
      alert("전체화면 모드가 지원되지 않습니다.");
    }
  };
  // 전체화면 모드 종료
  const exitFullscreen = () => {
    if (document.exitFullscreen) {
      document
        .exitFullscreen()
        .then(() => {
          setIsFullscreen(false);
          console.log("Exited fullscreen mode");
        })
        .catch((err) => {
          console.error("Error exiting fullscreen", err);
        });
    }
  };

  const ScoreSet = React.useCallback(
    (roomIndex, r_room) => {
      let copyScore;
      if (r_room.length === 0) {
        copyScore = [0, 0, 0, 0, 0];
      } else {
        let bankerScore = 0;
        let playerScore = 0;
        let tieScore = 0;
        let bPScore = 0;
        let pPScore = 0;
        for (let i = 0; i < r_room.length; i++) {
          switch (r_room[i].sWinner) {
            case "Banker":
              bankerScore += 1;
              break;
            case "Player":
              playerScore += 1;
              break;
            case "Tie":
              tieScore += 1;
              break;
            default:
              console.warn(`Unexpected sWinner value: ${r_room[i]}`);
              break;
          }
          switch (r_room[i].sPair) {
            case "Banker_Pair":
              bPScore += 1;
              break;
            case "Player_Pair":
              pPScore += 1;
              break;
            case "Player_Pair,Banker_Pair":
            case "Banker_Pair,Player_Pair":
              pPScore += 1;
              bPScore += 1;
              break;
            default:
              break;
          }
        }

        copyScore = [bankerScore, playerScore, tieScore, bPScore, pPScore];
      }
      setScore_room(roomIndex, copyScore);
    },
    [setScore_room]
  );

  const GunSet = React.useCallback(
    (roomIndex, r_room) => {
      let g1, g2, g3, g4;
      if (r_room.length === 0) {
        g1 = {};
        g2 = {};
        g3 = {};
        g4 = {};
      } else {
        let log = r_room;
        let FirstTie = 0;
        if (log.length > 0) {
          for (let i = 0; i < Math.min(8, log.length); i++) {
            if (log[i].sWinner === "Tie") {
              FirstTie++;
            } else {
              break;
            }
          }
        }
        log = log.slice(FirstTie, log.length);
        g1 = new BigRoad({
          array: log,
          row: Row,
          col: Gun1Cols,
          cut: true,
        }).getBigRoad();

        g2 = new BigEyeRoad({
          gubun: g1.gubunIndex,
          row: Row,
          col: Gun2Cols * 2,
          cut: true,
        }).getBigEyeRoad(g1.old);

        g3 = new SmallRoad({
          gubun: g1.gubunIndex,
          row: Row,
          col: Gun3Cols * 2,
          cut: true,
        }).getSmallRoad(g1.old);

        g4 = new CockroachRoad({
          gubun: g1.gubunIndex,
          row: Row,
          col: Gun4Cols * 2,
          cut: true,
        }).getCockroachRoad(g1.old);
      }
      setGun1(roomIndex, g1);
      setGun2(roomIndex, g2);
      setGun3(roomIndex, g3);
      setGun4(roomIndex, g4);
    },
    [setGun1, setGun2, setGun3, setGun4]
  );
  const initRoom = useRef(false);
  useEffect(() => {
    if (!socket) return;

    if (socket.connected && Where === "login" && !initRoom.current) {
      initRoom.current = true;
      console.log("초기화 로직 실행");
      setWhere("lobby");
      socket.emit("AbatarFill", {
        type: "u",
        sSite: SevUrl().listName,
      });
    }

    socket.on("AbatarFill", (data) => {
      console.log("AbatarFill", data);
      for (let i = 0; i < data.s_room.length; i++) {
        const s_roomData = data.s_room[i];
        const r_roomData = data.r_room[i];
        setRoom_s(i, s_roomData);
        setRoom_r(i, r_roomData);
        ScoreSet(i, r_roomData);
        GunSet(i, r_roomData);
      }
    });
    socket.on("statusLobby", (data) => {
      console.log("statusLobby Lobby");

      const roomIndex = s_room.findIndex((el) => el.RoomID === data.RoomID);
      if (roomIndex === -1) {
        return;
      }
      if (data.status === "Open" || data.status === "Close") {
        const updatedRoom = {
          ...s_room[roomIndex],
          active: data.status === "Open" ? 1 : 0,
          status: data.status.split("-")[0],
        };
        setRoom_s(roomIndex, updatedRoom);
      } else {
        const updatedRoom = {
          ...s_room[roomIndex],
          status: data.status.split("-")[0],
        };
        setRoom_s(roomIndex, updatedRoom);
      }
    });
    socket.on("enterSingleLobby", (data) => {
      console.log("enterSingleLobby", data);
      const roomIndex = s_room.findIndex((el) => el.RoomID === data.RoomID);
      if (roomIndex === -1) {
        return;
      }
      if (!Array.isArray(data.result)) {
        console.error("data.result is not an array:", data.result);
        return;
      }
      setRoom_s(roomIndex, data.s_room);
      setRoom_r(roomIndex, data.result);
      ScoreSet(roomIndex, data.result);
      GunSet(roomIndex, data.result);
    });

    setWhere("lobby");
    return () => {
      socket.off("AbatarFill");
      socket.off("statusLobby");
      socket.off("enterSingleLobby");
    };
  }, [GunSet, ScoreSet, Where, s_room, setRoom_r, setRoom_s, setWhere, socket]);

  // --- Render 시 하위 컴포넌트(게임 테이블) 렌더링 함수들 ---

  function Pool(params, index) {
    const roomIndex = s_room.findIndex((el) => el.RoomID === params.RoomID);
    if (!r_room[roomIndex] || !Array.isArray(r_room[roomIndex])) {
      return "";
    }
    const PoolShowTotal = 36;
    const PoolEmpty = 12;
    const PoolShowOver = 24;
    let diffLength = 0;
    let PoolData = [];
    if (r_room[roomIndex].length <= PoolShowOver) {
      PoolData = r_room[roomIndex];
    } else {
      diffLength = PoolShowTotal - r_room[roomIndex].length;
      const reLength =
        diffLength === 1
          ? -36
          : (((r_room[roomIndex].length % PoolShowTotal) % PoolEmpty) +
              PoolShowOver) *
            -1;
      PoolData = r_room[roomIndex].slice(reLength);
    }
    if (PoolData.length === 0 || !PoolData[index]) return "";
    let firstName = "";
    let lastName = "";
    switch (PoolData[index].sWinner) {
      case "Player":
        firstName += "P";
        break;
      case "Tie":
        firstName += "T";
        break;
      case "Banker":
        firstName += "B";
        break;
      default:
        firstName = "";
        break;
    }
    if (firstName !== "") {
      switch (PoolData[index].sPair) {
        case "Banker_Pair":
          lastName += "2";
          break;
        case "Player_Pair":
          lastName += "3";
          break;
        case "Player_Pair,Banker_Pair":
        case "Banker_Pair,Banker_Pair":
          lastName += "4";
          break;
        default:
          lastName += "1";
          break;
      }
    }
    return firstName === "" ? (
      <div></div>
    ) : (
      <div className={`${style[firstName]} ${style[`pair${lastName}`]}`}>
        {firstName}
      </div>
    );
  }

  function Gun1(data, index) {
    const roomIndex = s_room.findIndex((el) => el.RoomID === data.RoomID);
    if (
      r_room[roomIndex].length === 0 ||
      !Array.isArray(r_room[roomIndex]) ||
      !gun1[roomIndex] ||
      gun1[roomIndex].length === 0
    ) {
      return reEl;
    }
    let mod = Math.floor(index / Gun1Cols);
    let namuzi = index % Gun1Cols;
    if (!gun1?.[roomIndex]?.new?.[mod]?.[namuzi]) {
      return reEl;
    }
    const reName = gun1[roomIndex].new[mod][namuzi];
    if (reName.Win === "") {
      return reEl;
    } else {
      let name = reName.Win === "Player" ? "b" : "r";
      let num =
        reName.Pair === 3
          ? "4"
          : reName.Pair === 2
          ? "2"
          : reName.Pair === 1
          ? "3"
          : "1";
      return reName.Win === "" ? (
        reEl
      ) : (
        <div className={`${style[name]} ${style[`pair${num}`]}`}>
          {Gun1Tie(data, index)}
        </div>
      );
    }
  }

  function Gun1Tie(data, index) {
    const roomIndex = s_room.findIndex((el) => el.RoomID === data.RoomID);
    if (
      r_room[roomIndex].length === 0 ||
      !Array.isArray(r_room[roomIndex]) ||
      !gun1[roomIndex] ||
      gun1[roomIndex].length === 0
    ) {
      return reEl;
    }
    let mod = Math.floor(index / Gun1Cols);
    let namuzi = index % Gun1Cols;
    if (!gun1?.[roomIndex]?.new?.[mod]?.[namuzi]) {
      return reEl;
    }
    const reName = gun1[roomIndex].new[mod][namuzi];
    if (reName !== "" && reName.TieCount !== 0) {
      return <div className={style.tie}>{reName.TieCount}</div>;
    }
    return reEl;
  }

  function Gun2(data, index, jndex, origin) {
    const roomIndex = s_room.findIndex((el) => el.RoomID === data.RoomID);
    if (
      r_room[roomIndex].length === 0 ||
      !Array.isArray(r_room[roomIndex]) ||
      !gun2[roomIndex] ||
      gun2[roomIndex].length === 0
    ) {
      return "";
    }
    const reName = gun2[roomIndex][index][jndex];
    if (reName.Mark === "") {
      return "";
    } else {
      return reName.Mark === "Player" ? style["b"] : style["r"];
    }
  }

  function Gun3(data, index, jndex, origin) {
    const roomIndex = s_room.findIndex((el) => el.RoomID === data.RoomID);
    if (
      r_room[roomIndex].length === 0 ||
      !Array.isArray(r_room[roomIndex]) ||
      !gun3[roomIndex] ||
      gun3[roomIndex].length === 0
    ) {
      return "";
    }
    const reName = gun3[roomIndex][index][jndex];
    if (reName.Mark === "") {
      return "";
    } else {
      return reName.Mark === "Player" ? style["b"] : style["r"];
    }
  }

  function Gun4(data, index, jndex, origin) {
    const roomIndex = s_room.findIndex((el) => el.RoomID === data.RoomID);
    if (
      r_room[roomIndex].length === 0 ||
      !Array.isArray(r_room[roomIndex]) ||
      !gun4[roomIndex] ||
      gun4[roomIndex].length === 0
    ) {
      return "";
    }
    const reName = gun4[roomIndex][index][jndex];
    if (reName.Mark === "") {
      return "";
    } else {
      return reName.Mark === "Player" ? style["b"] : style["r"];
    }
  }

  function EnterProcess(data) {
    if (data.ShuffleOn) {
      return;
    }
    setRoomID(data.RoomID);

    setId(s_room.findIndex((el) => el.RoomID === data.RoomID));
    navigate("/Game");
    // const EndPoint = isLocalhost
    //   ? `http://localhost:3003/?token=${token}&list=${list}&RoomID=${data.RoomID}&caseName=${caseName}&country=${language}`
    //   : `https://avata-client.pd-live-services.com/game/?token=${token}&list=${list}&RoomID=${data.RoomID}&caseName=${caseName}&country=${language}`;
    // window.location.href = EndPoint;
  }
  function ExitProcess() {
    setToken("");
    setMb_name("");
    setRoomID("");
    setRoom_s([]);
    setRoom_r([]);
    setScore_room([]);
    setGun1([]);
    setGun2([]);
    setGun3([]);
    setGun4([]);

    navigate("/Login");
  }
  return (
    <div className={style.LobbyPage}>
      <div className={style.inPage}>
        <div className={style.header}>
          <h1 className={style["game-type"]}>
            <img src={`./header/${SevUrl().caseName}.png`} alt="" />
          </h1>
          <span className={style.notice}>
            <div className={style["marquee-container"]}>
              <span id="txt_welcome" className={style["marquee-text"]}>
                {getTranslation(language, "welcome")}
              </span>
            </div>
          </span>
          <ul className={style["user-info"]}>
            <li className={style.nick}>
              <i></i>
              <span>{mb_name}</span>
              <span id="txt_nim"></span>
            </li>
            <li className={style.money}></li>
            <li className={style["select-box"]}>
              <select name="lang" onChange={(e) => chLang(e.target.value)}>
                <option value="">LANGUAGE</option>
                <option value="ko">KOREAN</option>
                <option value="cn">CHINESE</option>
                <option value="en">ENGLISH</option>
                <option value="jp">JAPANESE</option>
              </select>
            </li>
          </ul>

          <ul className={style["quick-button"]}>
            <li>
              <button onClick={() => openGameInfo()}>
                <span id="t_btn_gameinfo">
                  {getTranslation(language, "gameInfo")}
                </span>
              </button>
            </li>
            <li>
              {isFullscreen ? (
                <button onClick={() => exitFullscreen()}>
                  <span id="t_btn_small">
                    {getTranslation(language, "exitFullscreen")}
                  </span>
                </button>
              ) : (
                <button onClick={() => enterFullscreen()}>
                  <span id="t_btn_full">
                    {getTranslation(language, "fullscreen")}
                  </span>
                </button>
              )}
            </li>
            <li>
              <button onClick={() => ExitProcess()}>
                <span id="t_btn_logout">
                  {getTranslation(language, "main")}
                </span>
              </button>
            </li>
          </ul>
        </div>

        <div className={style["game-list"]}>
          <div className={style["game-room"]}>
            {s_room.map((item, i) => (
              <div
                key={i}
                className={`${style.room_i} ${style.box}`}
                style={{ display: item.active === 1 ? "grid" : "none" }}
              >
                <div className={style.type}>
                  <h3>
                    <span>{item.RoomNumber}</span>
                  </h3>
                </div>
                <div className={style.scoreboard2}>
                  <div className={style.left}>
                    <ul className={style.beadplate}>
                      {Array.from({ length: 6 * Row }).map((_, index) => (
                        <div key={index} className={style.cell}>
                          {Pool(item, index)}
                        </div>
                      ))}
                    </ul>
                  </div>
                  <div className={style.right}>
                    <div className={style.upper}>
                      <ul className={style.bigroad}>
                        {Array.from({ length: Gun1Cols * Row }).map(
                          (_, index) => (
                            <div key={index} className={style.cell}>
                              {Gun1(item, index)}
                            </div>
                          )
                        )}
                      </ul>
                      <ul className={style.smallroad}>
                        {Array.from({ length: Gun2Cols * (Row / 2) }).map(
                          (_, index) => (
                            <div
                              key={index}
                              className={`${style.cell} ${style[index]}`}
                            >
                              {/* 왼쪽상단 */}
                              <div
                                className={
                                  Gun2(
                                    item,
                                    Math.floor(index / Gun2Cols) * 2,
                                    (index % Gun2Cols) * 2,
                                    index
                                  )
                                    ? style[
                                        Gun2(
                                          item,
                                          Math.floor(index / Gun2Cols) * 2,
                                          (index % Gun2Cols) * 2,
                                          index
                                        )
                                      ]
                                    : ""
                                }
                              ></div>
                              {/* 오른쪽상단 */}
                              <div
                                className={
                                  Gun2(
                                    item,
                                    Math.floor(index / Gun2Cols) * 2,
                                    (index % Gun2Cols) * 2 + 1,
                                    index
                                  )
                                    ? style[
                                        Gun2(
                                          item,
                                          Math.floor(index / Gun2Cols) * 2,
                                          (index % Gun2Cols) * 2 + 1,
                                          index
                                        )
                                      ]
                                    : ""
                                }
                              ></div>
                              {/* 왼쪽하단 */}
                              <div
                                className={
                                  Gun2(
                                    item,
                                    Math.floor(index / Gun2Cols) * 2 + 1,
                                    (index % Gun2Cols) * 2,
                                    index
                                  )
                                    ? style[
                                        Gun2(
                                          item,
                                          Math.floor(index / Gun2Cols) * 2 + 1,
                                          (index % Gun2Cols) * 2,
                                          index
                                        )
                                      ]
                                    : ""
                                }
                              ></div>
                              {/* 오른쪽하단 */}
                              <div
                                className={
                                  Gun2(
                                    item,
                                    Math.floor(index / Gun2Cols) * 2 + 1,
                                    (index % Gun2Cols) * 2 + 1,
                                    index
                                  )
                                    ? style[
                                        Gun2(
                                          item,
                                          Math.floor(index / Gun2Cols) * 2 + 1,
                                          (index % Gun2Cols) * 2 + 1,
                                          index
                                        )
                                      ]
                                    : ""
                                }
                              ></div>
                            </div>
                          )
                        )}
                      </ul>
                    </div>
                    <div className={style.lower}>
                      <ul className={style.bigeyeboy}>
                        {Array.from({ length: Gun3Cols * (Row / 2) }).map(
                          (_, index) => (
                            <div key={index} className={style.cell}>
                              {/* 왼쪽상단 */}
                              <div
                                className={
                                  Gun3(
                                    item,
                                    Math.floor(index / Gun3Cols) * 2,
                                    (index % Gun3Cols) * 2,
                                    index
                                  )
                                    ? style[
                                        Gun3(
                                          item,
                                          Math.floor(index / Gun3Cols) * 2,
                                          (index % Gun3Cols) * 2,
                                          index
                                        )
                                      ]
                                    : ""
                                }
                              ></div>
                              {/* 오른쪽상단 */}
                              <div
                                className={
                                  Gun3(
                                    item,
                                    Math.floor(index / Gun3Cols) * 2,
                                    (index % Gun3Cols) * 2 + 1,
                                    index
                                  )
                                    ? style[
                                        Gun3(
                                          item,
                                          Math.floor(index / Gun3Cols) * 2,
                                          (index % Gun3Cols) * 2 + 1,
                                          index
                                        )
                                      ]
                                    : ""
                                }
                              ></div>
                              {/* 왼쪽하단 */}
                              <div
                                className={
                                  Gun3(
                                    item,
                                    Math.floor(index / Gun3Cols) * 2 + 1,
                                    (index % Gun3Cols) * 2,
                                    index
                                  )
                                    ? style[
                                        Gun3(
                                          item,
                                          Math.floor(index / Gun3Cols) * 2 + 1,
                                          (index % Gun3Cols) * 2,
                                          index
                                        )
                                      ]
                                    : ""
                                }
                              ></div>
                              {/* 오른쪽하단 */}
                              <div
                                className={
                                  Gun3(
                                    item,
                                    Math.floor(index / Gun3Cols) * 2 + 1,
                                    (index % Gun3Cols) * 2 + 1,
                                    index
                                  )
                                    ? style[
                                        Gun3(
                                          item,
                                          Math.floor(index / Gun3Cols) * 2 + 1,
                                          (index % Gun3Cols) * 2 + 1,
                                          index
                                        )
                                      ]
                                    : ""
                                }
                              ></div>
                            </div>
                          )
                        )}
                      </ul>
                      <ul className={style.cockroachpig}>
                        {Array.from({ length: Gun4Cols * (Row / 2) }).map(
                          (_, index) => (
                            <div key={index} className={style.cell}>
                              {/* 왼쪽상단 */}
                              <div
                                className={
                                  Gun4(
                                    item,
                                    Math.floor(index / Gun4Cols) * 2,
                                    (index % Gun4Cols) * 2,
                                    index
                                  )
                                    ? style[
                                        Gun4(
                                          item,
                                          Math.floor(index / Gun4Cols) * 2,
                                          (index % Gun4Cols) * 2,
                                          index
                                        )
                                      ]
                                    : ""
                                }
                              ></div>
                              {/* 오른쪽상단 */}
                              <div
                                className={
                                  Gun4(
                                    item,
                                    Math.floor(index / Gun4Cols) * 2,
                                    (index % Gun4Cols) * 2 + 1,
                                    index
                                  )
                                    ? style[
                                        Gun4(
                                          item,
                                          Math.floor(index / Gun4Cols) * 2,
                                          (index % Gun4Cols) * 2 + 1,
                                          index
                                        )
                                      ]
                                    : ""
                                }
                              ></div>
                              {/* 왼쪽하단 */}
                              <div
                                className={
                                  Gun4(
                                    item,
                                    Math.floor(index / Gun4Cols) * 2 + 1,
                                    (index % Gun4Cols) * 2,
                                    index
                                  )
                                    ? style[
                                        Gun4(
                                          item,
                                          Math.floor(index / Gun4Cols) * 2 + 1,
                                          (index % Gun4Cols) * 2,
                                          index
                                        )
                                      ]
                                    : ""
                                }
                              ></div>
                              {/* 오른쪽하단 */}
                              <div
                                className={
                                  Gun4(
                                    item,
                                    Math.floor(index / Gun4Cols) * 2 + 1,
                                    (index % Gun4Cols) * 2 + 1,
                                    index
                                  )
                                    ? style[
                                        Gun4(
                                          item,
                                          Math.floor(index / Gun4Cols) * 2 + 1,
                                          (index % Gun4Cols) * 2 + 1,
                                          index
                                        )
                                      ]
                                    : ""
                                }
                              ></div>
                            </div>
                          )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
                <div className={style["bpt-time"]}>
                  <div id="bpt_time_89">
                    <div className={style.play_text_list}></div>
                  </div>
                </div>
                <div className={style["bpt-info"]}>
                  <ul>
                    <li className={style["txt-red"]}>
                      B <span id="bw89">{score_room[i][0]}</span>
                    </li>
                    <li className={style["txt-blue"]}>
                      P <span id="pw89">{score_room[i][1]}</span>
                    </li>
                    <li className={style["txt-green"]}>
                      T <span id="tw89">{score_room[i][2]}</span>
                    </li>
                  </ul>
                  <div className={style.status}>
                    {getTranslation(language, item.status.split("-")[0])}
                  </div>
                  <button
                    onClick={() => EnterProcess(item)}
                    className={style[`enter${language}`]}
                  ></button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
}

export default LobbyPage;
