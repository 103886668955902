import React, { useEffect } from "react";
import { Routes, Route, useNavigate, Navigate } from "react-router-dom";
import LoginPage from "./Login/LoginPage";
import LobbyPage from "./Lobby/LobbyPage";
import GamePage from "./Game/GamePage";
import { useSocket } from "./SocketContext";
function App() {
  const socket = useSocket();
  const navigate = useNavigate();

  useEffect(() => {
    if (!socket) return;

    if (!socket.connected) {
      socket.connect();
    }

    socket.on("connect", () => {
      console.log("connect");
      navigate("/Login");
      socket.emit("Ping");
    });
    socket.on("Pong", () => {
      setTimeout(() => {
        socket.emit("Ping");
      }, 3000);
    });

    socket.on("Duplicate_Login", () => {
      console.log("Socket Duplicate_Login");
      alert("다른 기기에서 로그인되어 접속이 종료됩니다");
      socket.disconnect();
    });

    socket.on("ErrorDisconnect", (data) => {
      alert(data.msg);
      window.history.back();
    });
    socket.on("connect_error", () => {
      socket.disconnect();
    });

    socket.on("disconnect", (data) => {
      console.log("Socket disconnected", data);
      socket.disconnect();
      navigate("/Login");
    });
    return () => {
      socket.off("connect");
      socket.off("Pong");
      socket.off("Duplicate_Login");
      socket.off("ErrorDisconnect");
      socket.off("connect_error");
      socket.off("disconnect");
    };
  }, [navigate, socket]);

  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<Navigate to="/Login" />} />
        <Route path="/Login" element={<LoginPage />} />
        <Route path="/Lobby" element={<LobbyPage />} />
        <Route path="/Game" element={<GamePage />} />/
      </Routes>
    </div>
  );
}

export default App;
