// store.js
import { create } from "zustand";

export const useStore = create((set) => ({
  // 소켓 인스턴스를 전역에서 사용할 수 있도록 추가

  token: "",
  setToken: (newToken) => set({ token: newToken }),
  mb_name: "",
  setMb_name: (newMb_name) => set({ mb_name: newMb_name }),
  language: "en",
  setLanguage: (newLanguage) => set({ language: newLanguage }),
  RoomID: "",
  setRoomID: (newRoomID) => set({ RoomID: newRoomID }),
  id: 0,
  setId: (newId) => set({ id: newId }),

  Where: "login",
  setWhere: (newWhere) => set({ Where: newWhere }),

  s_room: [],
  setRoom_s: (index, value) =>
    set((state) => {
      const newsRoom = [...state.s_room];
      newsRoom[index] = value;
      return { s_room: newsRoom };
    }),
  r_room: [],
  setRoom_r: (index, value) =>
    set((state) => {
      const newrRoom = [...state.r_room];
      newrRoom[index] = value;
      return { r_room: newrRoom };
    }),

  score_room: [],
  setScore_room: (index, value) =>
    set((state) => {
      const newScoreRoom = [...state.score_room];
      newScoreRoom[index] = value;
      return { score_room: newScoreRoom };
    }),

  gun1: [],
  setGun1: (index, value) =>
    set((state) => {
      const newGun = [...state.gun1];
      newGun[index] = value;
      return { gun1: newGun };
    }),

  gun2: [],
  setGun2: (index, value) =>
    set((state) => {
      const newGun = [...state.gun2];
      newGun[index] = value;
      return { gun2: newGun };
    }),
  gun3: [],
  setGun3: (index, value) =>
    set((state) => {
      const newGun = [...state.gun3];
      newGun[index] = value;
      return { gun3: newGun };
    }),
  gun4: [],
  setGun4: (index, value) =>
    set((state) => {
      const newGun = [...state.gun4];
      newGun[index] = value;
      return { gun4: newGun };
    }),
}));
